<template>
    <div class="com-play-live">
        <a-button @click="startPlay" type="primary" v-if="!custom">播放</a-button>
        <div v-else @click="startPlay">
            <slot></slot>
        </div>
        <a-modal v-model:visible="show.play" title="监控预览" :footer="null" @cancel="clearPlay" width="700px">
            <div v-if="playType == 1">
			    <div id="previewPlayer"></div>
            </div>
            <div v-if="playType == 2 ">
                <com-play-gb
                    ref="playRef" 
                    :device-id="deviceId" 
                    :channle-id="channelId"
                    :webrtc="src">
                </com-play-gb>
            </div>
		</a-modal>
    </div>
</template>
<script>
import { nextTick, reactive, toRefs } from 'vue'
import HlsJsPlayer from 'xgplayer-hls.js'
import ComPlayGb from './com-play-gb.vue'

export default {
    name:'com-play-live',
    components:{
        ComPlayGb
    },
    props:{
        playType:{  //播放类型 1普通监控  2国标设备
            type:Number,
            default:1
        },
        src:{       //播放地址
            type:String,
        },
        custom:{    //自定义播放按钮
            type:Boolean,
            default:false
        },
        deviceId:{
            type:[Number,String]
        },
        channelId:{
            type:[Number,String]
        },
    },
    setup(props) {
        const state = reactive({
            show:{
                play:false,
            },
            player:null,
            playRef:null,
        })

        function startPlay(){
            state.show.play = true
            nextTick(()=>{
                state.player = new HlsJsPlayer({
                    id: 'previewPlayer',
                    url:props.src,
                    autoplay:true,
                    fluid: true,
                    muted:true,
                    screenShot:true,
                    lang:'zh-cn',
                    error:"监控不在线",
                    useHls: true
                });
            })
        }

        function clearPlay(){
            if( props.playType == 1 ){
                state.player.destroy(true)
                state.player.once('destroy',()=>{
                    state.player = null
                })
            }

            if( props.playType == 2 ){
                state.playRef.pullFlow('stop')
            }

            state.show.play = false
        }

        return{
            ...toRefs(state),
            startPlay,
            clearPlay
        }
    },
}
</script>