<template>
    <div class="page-live-map">
        <div class="plm-card" :class="show.live ?'plm-card-show':''">
            <template v-if="previewLive">
                <div class="plm-card-head">
                    <div>{{previewLive.title}}</div>
                    <i class="ri-close-line ri-xl" @click="show.live = false"></i>
                </div>
                <img class="plm-cover" :src="previewLive.cover" alt="">
                <div class="plm-preview" v-if="!previewLive.is_ns">
                    <com-play-live :src="previewLive.src" :custom="true">
                        <i class="ri-space-ship-line"></i>
                        <div>预览</div>
                    </com-play-live>
                </div>
                <div class="plm-preview" v-else>
                    <com-play-live 
                        :play-type="2"
                        :custom="true" 
                        :src="previewLive.webrtc" 
                        :device-id="previewLive.device_id" 
                        :channel-id="previewLive.channel_id"
                    >
                        <i class="ri-space-ship-line"></i>
                        <div>预览</div>
                    </com-play-live>
                </div>
                <div class="plm-type">{{previewLive.is_ns ? '国标设备' :'监控设备'}}</div>
                <div class="plm-add">
                    <i class="ri-map-pin-line"></i>
                    <span>{{previewLive.address}}</span>
                </div>
            </template>
        </div>
        <a-tooltip title="切换列表展示" @click="changeList">
            <div class="plm-menu"><i class="ri-list-check ri-xl ri-top"></i></div>
        </a-tooltip>
        <div class="plm-cate">
            <ul>
                <li :class="show.typeIndex == item.id ? 'type-item':''" 
                    v-for="(item,index) in typeData" 
                    :key="index"
                >
                    <div class="type-name" 
                        @click="show.typeIndex == item.id ?  (show.typeIndex = -1) : (show.typeIndex = item.id) " 
                    >
                        <div><i class="ri-arrow-right-s-line ri-lg ri-top"></i></div>
                        {{item.name}}
                    </div>
                    <div class="plm-cate-live">
                        <template v-for="(val,ind) in liveList" :key="ind">
                            <div class="live-title" v-if="item.id == val.category_id" @click="toLookLive(val)">
                                <img :src="val.cover" class="live-cover" alt="">
                                {{val.title}}
                            </div>
                        </template>
                    </div>
                </li>
            </ul>
        </div>

        <div class="plmMap" id="dfmap" style="width:100%; height:700px" ></div>
    </div>
</template>
<script>

import GdMap from '@/util/GdMap'
import { reactive,nextTick, toRefs } from 'vue';
import tool from '@/util/tool';
import liveModel from '@/api/live';
import ComPlayLive from '@/components/layout/live/com-play-live'
import router from '@/router';

export default {
    name:'PageLiveMap',
    components:{
        ComPlayLive
    },
    setup(props,context) {
        const state = reactive({
            map:null,
            liveList:[],
            typeData:[],
            show:{
                live:false,
                typeIndex:-1
            },
            previewLive:null
        })
        ;(function(){
            let map = new GdMap({el:'dfmap',drawType:'live',zoom:14})
            nextTick(()=>{
                map.initMap(true)
                let icon = tool.getStaticSrc("monitor.png")
                liveModel.getLiveList(1,99,null,res=>{
                    state.liveList = res.list
                    let location = [],extData = []
                    res.list.forEach((item,index)=>{
                        if( item.longitude && item.latitude ){
                            location.push([item.longitude , item.latitude])
                            extData.push({id:item.id,index:index})
                        }
                    })

                    map.addBatchMarker(location,extData,icon,result=>{
                        state.previewLive = state.liveList[result.index]
                        state.show.live = true
                    })
                })
            })
            state.mapObj = map

            liveModel.getLiveTypeList(1,999).then(res=>state.typeData = res.list)

        })();

        function toLookLive(data){
            if( data.longitude && data.latitude ){
                state.mapObj.setCenter([data.longitude , data.latitude],16)
                state.previewLive = data
                state.show.live = true
            }else{
                tool.confirm("当前监控没有设置经纬度","立即设置","暂不设置").then(()=>{
                    let path = data.shop_id? '/many_store/live/liveEdit' : '/live/liveEdit'
                    router.push({path:path,query:{id:data.id}})
                }).catch(()=>{})
            }
        }

        function changeList(){
            context.emit("changePacel")
        }

        return{
            ...toRefs(state),
            toLookLive,
            changeList
        }
        
    },
}
</script>
<style scoped lang="scss">
.page-live-map{
    position: relative;
    .plmMap{
        position: fixed;
    }
}
.plm-card{
    width: 300px;
    background: #fff;
    position: absolute;
    z-index: 1;
    margin: 12px 0 0 12px;
    padding: 12px 0;
    box-shadow: 1px 4px 20px #c3c3c3;
    border-radius: 5px;
    transition: transform 0.3s linear, opacity 0.3s linear;
    transform: scale(0.1, 0.1);
    opacity: 0;

    &-head{
        width: 100%;
        padding: 0 8px;
        text-align: right;
        height: 30px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;

        div{
            font-weight: bold;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .plm-cover{
        width: 100%;
        height: 160px;
    }
    .plm-type{
        font-size: 12px;
        color: #999;
        padding: 4px 8px;
    }
    .plm-add{
        font-size: 12px;
        padding: 4px 6px;
    }

    .plm-preview{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #1890ff;
        position: absolute;
        margin-top: -20px;
        margin-left: 240px;
        color: #fff;
        text-align: center;
        line-height: 12px;
        box-sizing: border-box;
        padding-top: 8px;
        cursor: pointer;
        div{
            font-size: 12px;
        }
    }
}
.plm-card-show{
    opacity: 1;
    transform: scale(1, 1);
}

.plm-menu{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    position: absolute;
    right: 320px;
    z-index: 1;
    box-shadow: 1px 4px 20px #c3c3c3;
    line-height: 40px;
    margin-top: 12px;
    cursor: pointer;
}

.plm-cate{
    width: 300px;
    max-height: 700px;
    position: absolute;
    z-index: 1;
    right: 0;
    background: #fff;
    box-shadow: 1px 4px 20px #c3c3c3;
    border-radius: 5px;
    margin-top: 12px;
    cursor: pointer;

    ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            cursor: pointer;
            max-height: 40px; 
            min-height: 40px;
            border-bottom: 1px solid #f4f4f4;
            transition: max-height 0.3s linear;
            
            .type-name{
                display: flex;
                align-items: center;
                height: 40px;
                margin-left: 12px;

                >div{
                    transition: transform 0.3s linear;
                }
            }
        }
        li:last-child{
            border-bottom: none;
        }

        .type-item {
            max-height: 340px;
            .plm-cate-live{
                max-height: 300px;
            }

            .type-name{
                >div{
                    transform: rotate(90deg);
                }
            }
        }
    }

    &-live{
        max-height: 0; 
        background: #fff;
        box-sizing: border-box;
        padding-left: 28px;
        overflow: hidden;
        overflow-y: auto;
        transition: max-height 0.3s linear;
        line-height: 40px;

        .live-title{
            font-size: 13px;
            border-bottom: 1px solid #f4f4f4;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .live-cover{
            width: 24px;
            height: 24px;
        }
    }

}


</style>